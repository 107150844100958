<template>
    <v-container>
        <validation-observer ref="observer">
            <form>
                <v-row>
                    <v-col cols="12" sm="6">
                        <kurcc-date-picker :date.sync="localItem.inspection_due_date" :grow="false"
                                           :name="$vuetify.lang.t('$vuetify.pages.inspections.dueDate')"
                                           :outlined="false"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <validation-provider v-slot="{ errors }" name="Deadline" rules="required|numeric">
                            <v-text-field v-model="localItem.deadline_days" :error-messages="errors"
                                          :label="$vuetify.lang.t('$vuetify.pages.inspections.deadlineDays')"
                                          :min="0" type="number"/>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <kurcc-autocomplete
                            :display-function="getStaffFullName"
                            :filter="filterStaffByName"
                            :items="inspectors"
                            :loading="isLoadingInspectors"
                            :multiple="false"
                            :name="$vuetify.lang.t('$vuetify.general.inspector')"
                            :selected-items.sync="localItem.inspector"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <kurcc-autocomplete
                            :disabled="isGroupInspectionsPage"
                            :items="groups"
                            :loading="isLoadingGroups || isLoadingGroup"
                            :multiple="false"
                            :name="$vuetify.lang.t('$vuetify.general.group')"
                            :selected-items.sync="localItem.group"/>
                    </v-col>
                    <v-col v-if="isDefaultItem" cols="12" sm="6">
                        <kurcc-autocomplete
                            :disabled="isBranchInspectionsPage"
                            :display-function="prepareBranchName"
                            :filter="filterBranch"
                            :items="branches"
                            :loading="isLoadingBranches || isLoadingBranch"
                            :multiple="false"
                            :name="$vuetify.lang.t('$vuetify.general.branch')"
                            :selected-items.sync="localItem.branch"/>
                    </v-col>
                </v-row>
            </form>
        </validation-observer>
    </v-container>
</template>

<script>
import {filterStaffByName, getStaffFullName} from "@/modules/staff/helpers/kurccAutocompleteHelpers";
import {ValidationObserver} from 'vee-validate'

export default {
    name: "KurccInspectionsDialog",
    props: {
        item: {
            required: true
        }
    },
    components: {
        KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete'),
        KurccDatePicker: () => import('@/modules/app/components/KurccDatePicker'),
        ValidationObserver,
    },
    data() {
        return {
            inspectionDateMenu: false,
            isLoadingGroups: false,
            groups: [],
            isLoadingInspectors: false,
            inspectors: [],
            isLoadingBranches: false,
            branches: [],
            filterStaffByName,
            getStaffFullName,
            isLoadingBranch: false,
            isLoadingGroup: false,
            opened: false
        }
    },
    computed: {
        localItem: {
            get() {
                return this.item;
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        isDefaultItem() {
            return this.localItem.id === null
        },
        getBranchId() {
            return this.$route.params.branch_id
        },
        getSiteId() {
            return this.$route.params.site_id
        },
        getGroupId() {
            return this.$route.params.group_id
        },
        isBranchInspectionsPage() {
            return !!this.getBranchId
        },
        isSiteInspectionsPage() {
            return !!this.getSiteId
        },
        isGroupInspectionsPage() {
            return !!this.getGroupId
        }
    },
    watch: {
        localItem: {
            handler: 'fetchDataWhenDialogOpens',
            immediate: true
        }
    },
    methods: {
        fetchDataWhenDialogOpens() {
            this.$refs.observer?.reset()
            if (!this.opened) {
                if (this.isBranchInspectionsPage && this.isDefaultItem && !this.isLoadingBranch) this.fetchBranch()
                if (this.isGroupInspectionsPage && this.isDefaultItem && !this.isLoadingGroup) this.fetchGroup()
                this.opened = true
            } else this.opened = false
        },
        getAllGroups() {
            this.isLoadingGroups = true
            this.$store.dispatch('getAllGroups', {withIncludes: false}).then((res) => {
                    this.groups = res.items
                }
            ).finally(() => (this.isLoadingGroups = false))
        },
        getAllInspectors() {
            this.isLoadingInspectors = true
            this.$store.dispatch('getAllStaff', {withIncludes: false}).then((res) => {
                    this.inspectors = res.items
                }
            ).finally(() => (this.isLoadingInspectors = false))
        },
        getAllBranches() {
            this.isLoadingBranches = true
            let additionalParams = this.isSiteInspectionsPage ? [`site_id=${this.getSiteId}`] : []
            this.$store.dispatch('getAllBranches', {
                withIncludes: false, additionalParams
            }).then((res) => {
                    this.branches = res.items
                }
            ).finally(() => (this.isLoadingBranches = false))
        },
        fetchBranch() {
            if (this.isBranchInspectionsPage) {
                this.isLoadingBranch = true
                this.$store.dispatch('getBranch', {id: this.getBranchId})
                    .then(res => {
                        this.localItem.branch = res
                        this.isLoadingBranch = false
                    })
            }
        },
        fetchGroup() {
            if (this.isGroupInspectionsPage) {
                this.isLoadingGroup = true
                this.$store.dispatch('getGroup', {id: this.getGroupId})
                    .then(res => {
                        this.localItem.group = res
                        this.isLoadingGroup = false
                    })
            }
        },
        prepareBranchName(data) {
            return data.item.brand_name_en + ' #' + data.item.number
        },
        filterBranch(item, queryText) {
            const name = (item.brand_name_en + ' #' + item.number).toLowerCase()
            const searchText = queryText.toLowerCase()

            return name.indexOf(searchText) > -1
        },
        initialize() {
            this.getAllInspectors()
            this.getAllGroups()
            this.getAllBranches()
            this.fetchBranch()
            this.fetchGroup()
        }
    },
    created() {
        this.initialize()
    }
}
</script>
